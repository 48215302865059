import React from 'react';

const CircularLoader = () => (
  <div className="flex items-center justify-center h-screen">
    <svg className="animate-spin h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-75" cx="12" cy="12" r="10" stroke="#4c4b4b" strokeWidth="4"></circle>
      <path className="" fill="#b87e02" d="M4 12a8 8 0 018-8v8H4z"></path>
    </svg>
  </div>
);

export default CircularLoader;
