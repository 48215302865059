import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CartProvider } from "./context/CartContext";
import "./App.css";
import CircularLoader from "./loader/CircularLoader";

// Lazy loading components
const ForgotPassPage = lazy(() => import("./pages/ForgotPass"));
const LandingPage = lazy(() => import("./pages/landing/Landing"));
const CartPage = lazy(() => import("./pages/Cart"));
const ForgotEmailPage = lazy(() => import("./pages/ForgotEmail"));
const LoginPage = lazy(() => import("./pages/Login"));
const LogoutPage = lazy(() => import("./pages/Logout"));
const OrderId = lazy(() => import("./pages/order/Order"));
const OtpPage = lazy(() => import("./pages/Otp"));
const SignUpPage = lazy(() => import("./pages/Signup"));
const OrdersPage = lazy(() => import("./pages/Orders"));
const ProdId = lazy(() => import("./pages/product/Product"));
const AddressPage = lazy(() => import("./pages/Address"));
const AboutPage = lazy(() => import("./pages/About"));

function App() {
  return (
    <CartProvider>
      <BrowserRouter>
        <Suspense fallback={<CircularLoader />}>
          <Routes>
            <Route path="/forgotpass" element={<ForgotPassPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/forgotemail" element={<ForgotEmailPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/otp" element={<OtpPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/address" element={<AddressPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/order/:orderId" element={<OrderId />} />
            <Route path="/product/:prodId" element={<ProdId />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;

// // import logo from "./logo.svg";
// import "./App.css";
// import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
// import ForgotPassPage from "./pages/ForgotPass";
// import LandingPage from "./pages/landing/Landing";
// import CartPage from "./pages/Cart";
// import ForgotEmailPage from "./pages/ForgotEmail";
// import LoginPage from "./pages/Login";
// import LogoutPage from "./pages/Logout";
// import OrderId from "./pages/order/Order";
// import OtpPage from "./pages/Otp";
// import SignUpPage from "./pages/Signup";
// import OrdersPage from "./pages/Orders";
// import ProdId from "./pages/product/Product";

// import { CartProvider } from "./context/CartContext";
// import AddressPage from "./pages/Address";
// import AboutPage from "./pages/About";

// function App() {
//   return (
//     // <div className="App">
//     <CartProvider>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/forgotpass" element={<ForgotPassPage />} />
//           <Route path="/" element={<LandingPage />} />
//           <Route path="/cart" element={<CartPage />} />
//           <Route path="/forgotemail" element={<ForgotEmailPage />} />
//           <Route path="/login" element={<LoginPage />} />
//           <Route path="/logout" element={<LogoutPage />} />
//           <Route path="/otp" element={<OtpPage />} />
//           <Route path="/signup" element={<SignUpPage />} />
//           <Route path="/orders" element={<OrdersPage />} />
//           <Route path="/address" element={<AddressPage />} />
//           <Route path="/about" element={<AboutPage />} />
//           <Route path="/order/:orderId" element={<OrderId />} />
//           <Route path="/product/:prodId" element={<ProdId />} />
//         </Routes>
//       </BrowserRouter>
//     </CartProvider>
//   );
// }

// export default App;
